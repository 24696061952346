.footer {
	background: #222222; /* Old browsers */
	background: -moz-linear-gradient(top,  #222222 0%, #000000 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top,  #222222 0%,#000000 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom,  #222222 0%,#000000 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#222222', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
	color: @whiteColor;
	padding-top: 10px;
	padding-bottom: 20px;
	
	a {
		color: @whiteColor;
	}

	&__title {
		display: block;
		text-transform: uppercase;
		font-style: 15px;
		font-weight: 500;
		margin-bottom: 15px;
	}
	&__footer {
		margin-top: 66px;
		text-align: center;
		span {
			display: block;
		}
		// Large, Medium and Small devices
		@media (min-width: @screen-sm-min) {
			text-align: right;
			span {
				display: inline-block;
				&:first-child:after, &:first-child::after {
					content: "|";
					display: inline-block;
					margin: 0 10px;
					vertical-align: text-bottom;
				}
			}
		}
	}
}