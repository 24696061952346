.header {

    &__logo {
        display: none;
        position: absolute;
        top: 0;
        left: 15px;
        margin-left: 0;
        z-index: 1000;
        -webkit-transition: top 0.4s ease-in-out;
        -moz-transition: top 0.4s ease-in-out;
        -ms-transition: top 0.4s ease-in-out;
        -o-transition: top 0.4s ease-in-out;
        transition: top 0.4s ease-in-out;
        a {
            display: block;
            background: url(../images/logo.png) no-repeat;
            width: ~"calc(163px * 0.65)";
            height: ~"calc(249px * 0.65)";
            background-size: ~"calc(163px * 0.65)" ~"calc(540px * 0.65)";
            font-size: 0;
            -webkit-transition: width 0.4s ease-in-out, height 0.4s ease-in-out, background-size 0.4s ease-in-out;
            -moz-transition: width 0.4s ease-in-out, height 0.4s ease-in-out, background-size 0.4s ease-in-out;
            -ms-transition: width 0.4s ease-in-out, height 0.4s ease-in-out, background-size 0.4s ease-in-out;
            -o-transition: width 0.4s ease-in-out, height 0.4s ease-in-out, background-size 0.4s ease-in-out;
            transition: width 0.4s ease-in-out, height 0.4s ease-in-out, background-size 0.4s ease-in-out;
        }
        @media (min-width: 200px) {
            display: block;
        }
        // ExtraSmall devices
        @media (min-width: @screen-xs-min) and (max-width: @screen-xs-max) { 
            a {
                width: ~"calc(163px * 0.75)";
                height: ~"calc(249px * 0.75)";
                background-size: ~"calc(163px * 0.75)" ~"calc(540px * 0.75)";
            }
        }
        // Small devices
        @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) { 
            left: 50%;    
            margin-left: -362px;
            a {
                width: ~"calc(163px * 0.85)";
                height: ~"calc(249px * 0.85)";
                background-size: ~"calc(163px * 0.85)" ~"calc(540px * 0.85)";
            }
        }

        // Medium devices
        @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
            left: 50%;
            margin-left: -474px;
            a {
                width: ~"calc(163px * 1)";
                height: ~"calc(249px * 1)";
                background-size: ~"calc(163px * 1)" ~"calc(540px * 1)";
            }
        }

        // Large devices
        @media (min-width: @screen-lg-min) {
            left: 50%;
            margin-left: -499px;
            a {
                width: ~"calc(163px * 1)";
                height: ~"calc(249px * 1)";
                background-size: ~"calc(163px * 1)" ~"calc(540px * 1)";
                background-position: 0 0;
            }
        }
        &-hidden {
            top: -72px;
            -webkit-transition: top 0.4s ease-in-out;
            -moz-transition: top 0.4s ease-in-out,;
            -ms-transition: top 0.4s ease-in-out;
            -o-transition: top 0.4s ease-in-out;
            transition: top 0.4s ease-in-out;

            a {
                background-position: 0 ~"calc(-251px * 0.65)";
            }
            // ExtraSmall devices
            @media (min-width: @screen-xs-min) and (max-width: @screen-xs-max) { 
                top: -90px;
                a {
                    background-position: 0 ~"calc(-251px * 0.75)";
                }
            }
            // Small devices
            @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) { 
                top: -106px;
                a {
                    background-position: 0 ~"calc(-251px * 0.85)";
                }
            }
        }
        &-hidden2 {
            top: -197px;
            *visibility: hidden;
            -webkit-transition: top 0.4s ease-in-out, visibility 0.4s ease-in-out;
            -moz-transition: top 0.4s ease-in-out, visibility 0.4s ease-in-out,;
            -ms-transition: top 0.4s ease-in-out, visibility 0.4s ease-in-out;
            -o-transition: top 0.4s ease-in-out, visibility 0.4s ease-in-out;
            transition: top 0.4s ease-in-out, visibility 0.4s ease-in-out;
            a {
                background-position: 0 0;
            }
            // Small devices
            @media (min-width: 650px) { 
                top: 0;
            }
        }
    }
    &__login {
        padding-top: 5px;
        padding-bottom: 5px;
        width: 100%;
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,dddddd+100 */
        background: #ffffff; /* Old browsers */
        background: -moz-linear-gradient(top,  #ffffff 0%, #dddddd 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  #ffffff 0%,#dddddd 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  #ffffff 0%,#dddddd 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#dddddd',GradientType=0 ); /* IE6-9 */
        color: @blackColor;
        font-size: 15px;
        @media (min-width: 570px) {
        padding-top: 11px;
        padding-bottom: 10px;
        }
    }

    .form {
        font-size: 13px;
        text-align: center;
        font-size: 0;
        margin-top: 15px;
        // Large devices and Medium device
        @media (min-width: @screen-sm-min) {
            text-align: right;
        }
        // Large devices and Medium device
        @media (min-width: @screen-sm-min) {
            font-size: 13px;
            margin-top: 0;
        }  
        &-group {
            display: block;
            max-width: 350px;
            margin: 0 auto 10px auto; 
            // Large devices and Medium device
            @media (min-width: @screen-sm-min) {
                display: inline-block;
                margin-top: -3px;
                margin-bottom: 0;
                max-width: 100%;
                &:first-child {
                    margin-left: 7px;
                }
            }  
            .form-control {
                font-size: 13px;
                height: 30px;
                line-height: 30px;
                padding: 0 15px;
                -webkit-border-radius: 15px;
                -moz-border-radius: 15px;
                border-radius: 15px;                
            }
            .btn {
                width: 30px;
                height: 30px;
                padding: 0;
                margin: -3px 0 0 0;
                line-height: 0;
                font-size: 0; 
                border: 0;
                -webkit-border-radius: 15px;
                -moz-border-radius: 15px;
                border-radius: 15px; 
                vertical-align: middle;
                background-color: @redColor;
                &:hover, &:focus, &.active {
                    background-color: #222222;
                }

                span { 
                display: block;
                width: 18px;
                height: 18px;
                font-size: 0;
                line-height: 0;
                background: transparent url(../images/sprites/sprites.png) no-repeat 0 -47px; 
                -webkit-border-radius: 15px;
                -moz-border-radius: 15px;
                border-radius: 15px;  
                margin-left: 6px;             
                }                  
            }
        }
    }
}

.navbar {
    min-height: 57px;
    border-radius: 0;
    &-login {
        // For js size-change detection
       -moz-transition:width 0.01s, height 0.01s;
       -webkit-transition:width 0.01s, height 0.01s;
       -o-transition:width 0.01s, height 0.01s;
       transition:width 0.01s, height 0.01s;
       //
        #login-form__btn {
            float: right;
            padding: 11px 37px 10px 2px;
            font-size: 0;
            line-height: 18px;
            font-weight: 800;
            text-decoration: none;
            color: @whiteColor;
            border: 0;
            background-color: @redColor;
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            border-radius: 10px;  
            // Large devices and Medium device
            @media (min-width: @screen-xs-min) {
                font-size: 13px;
                line-height: normal;
                padding: 10px 40px 10px 15px;
                -webkit-border-radius: 15px;
                -moz-border-radius: 15px;
                border-radius: 15px; 
            }     
            // Large devices and Medium device
            @media (min-width: @screen-sm-min) {
                display: none;
            }       
            &:after, &::after {
                content: "\f007";
                font-family: FontAwesome;
                position: absolute;
                font-size: 20px;
                font-weight: normal;
                width: 18px;
                height: 18px;
                padding-left: 10px;


                // Large, Medium, Small and ExtraSmall devices
                @media (min-width: @screen-xs-min) {
                    content: "";
                    background: url(../images/sprites/sprites.png) no-repeat -47px -19px;
                    margin-left: 10px;
                }   
            }    
            &[aria-expanded="true"]:after, &[aria-expanded="true"]::after {
                // ExtraSmall devices
                @media (min-width: @screen-xs-min) {
                    background-position: -18px -47px;
                }      
            }            
        }  
        @media (min-width: @screen-sm-min) {
            .navbar-header {
                float: left;
            }
            .navbar-toggle {
                display: none;
            }
            .navbar-collapse {
                border-top: 0 none;
                box-shadow: none;
                width: auto;
            }
            .navbar-collapse.collapse {
                display: block !important;
                height: auto !important;
                padding-bottom: 0;
                overflow: visible !important;
            }
            .navbar-nav {
                float: left !important;
                margin: 0;
            }
            .navbar-nav>li {
                float: left;
            }
            .navbar-nav>li>a {
                padding-top: 15px;
                padding-bottom: 15px;
            }
        }
    }
    &-wrapper {
        height: 57px;
        position: relative;
        z-index: 999;
    }   
    &-default { 
        border: 0;
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#222222+0,000000+23,000000+100 */
        background: #222222; /* Old browsers */
        background: -moz-linear-gradient(top,  #222222 0%, #000000 57px, #000000 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  #222222 0%,#000000 57px,#000000 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  #222222 0%,#000000 57px,#000000 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#222222', endColorstr='#000000',GradientType=0 ); /* IE6-9 */

        .navbar {
            &-nav { 
                margin:0;
                max-height: 100%;
                >li {
                    margin:0;
                    padding: 0;

                    >a {
                        margin:0;
                        padding: 19px 15px 18px 15px;
                        color: @whiteColor;
                        text-decoration: none;

                    }
                    &.active>a, &.active>a:hover, &.active>a:focus, >a:hover, >a:focus {
                        text-decoration: none;
                        color: @whiteColor;
                        background-color: @redColor;                           
                    }
                } 
                              
            }
            &-toggle {
                border: 0;
                margin-right: 5px;
                @media (min-width: @screen-xs-min) and (max-width: @screen-xs-max) {
                    margin-right: 10px;
                }
                @media (min-width: @screen-sm-min) {
                    margin-right: 0;
                }
                                
                &:hover {
                    background-color: @blackColor;    
                } 
                &:focus {
                    background-color: transparent;
                }  

                .icon-bar {
                    background-color: @whiteColor;
                }                
            }
        
            &-header {
                @media (max-width: 186px) {
                    text-align: center;
                }
            }
            &-brand {
                margin: 50px auto 10px auto !important;
                display: block;
                float: none;

                width: 86px;
                height: 31px;
                font-size: 0;
                line-height: 0;
                background: url(../images/logo.png) no-repeat;
                background-position: 0 -509px;
                @media (min-width: 187px) {     
                    margin: 12px !important;
                }
                // ExtraSmall devices
                @media (min-width: @screen-xs-min) and (max-width: @screen-xs-max) {
                    margin: 12px 0 12px 18px !important;
                }
                // Small devices
                @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
                    margin: 12px 0 12px 24px !important;
                }
                // Large devices and Medium device
                @media (min-width: @screen-md-min) {
                    margin: 12px 0 12px 35px !important;
                }
            }
            &-collapse {
                padding: 0;
                border: 0; 
            }
        }
        &.affix {
            top: 0;
            right: 0;
            left: 0;
            width: 100%;
            z-index: 3;
        }
    }
}
