.section {
    &--white {
		position: relative;
        padding: 30px 0 40px 0;
        margin: -30px 0 0 0;
    	background-color: @whiteColor;
	}
	&--slider {
    	background-color: @whiteColor;
		position: relative;
		z-index: 2;
        color: #fff;
        margin-bottom: 30px;
		-webkit-box-shadow: 0px 3px 7px 0px rgba(0,0,0,0.35);
		-moz-box-shadow: 0px 3px 7px 0px rgba(0,0,0,0.35);
		box-shadow: 0px 3px 7px 0px rgba(0,0,0,0.35);
    }
    &--news {
    	background-color: @whiteColor;
		position: relative;
        padding: 30px 0 30px 0;
        margin: -30px 0 30px 0;
		-webkit-box-shadow: 0px 3px 7px 0px rgba(0,0,0,0.35);
		-moz-box-shadow: 0px 3px 7px 0px rgba(0,0,0,0.35);
		box-shadow: 0px 3px 7px 0px rgba(0,0,0,0.35);
		.col-md-6:nth-of-type(2) .page-header {
			margin-bottom: 0;
		}
    	.news {
    		padding-bottom: 10px;
			margin-bottom: 25px;
			border-bottom: 1px solid #e8e8e8;
			@media (min-width: @screen-xs-min) {
    			padding-bottom: 20px;
			}
    		&__title {
    			&-date {
					display: inline-block;
					font-size: 15px;
					padding-right: 10px;
    			}
    			&-name {
					display: block;
					font-size: 15px;
					font-weight: 800;
					color: @redColor;
					// Extra small devices
    				@media (min-width: @screen-xs-min) { 
    					display: inline-block;
						padding-left: 10px;
						&:before, &::before {
							content: "|";
							position: absolute;
							color: #434343;
							font-size: 18px;
							margin: -5px 0 0 -15px;
						}
					}
    			}
    		}
    		&__desc {
    			font-size: 13px;
    			color: #444444;
    			display: block;
    			padding-top: 10px;
    			padding-bottom: 10px;
				// Large, Medium, Small and ExtraSmall devices
				@media (min-width: @screen-xs-min) {
    				display: inline-block;
				}
    		}
    		&__btn {
    			font-size: 15px;
    			color: #444444;
    			display: inline-block;
    			float: right;
    			margin-bottom:10px;
    			padding: 8px 10px;
				-webkit-border-radius: 6px;
				-moz-border-radius: 6px;
				border-radius: 6px;
    			&:after, &::after {
    				content: "";
    				display: inline-block;
    				width: 19px;
    				height: 19px;
    				margin-left: 5px;
					vertical-align: text-bottom;
    				background: url(../images/sprites/sprites.png) no-repeat 0 -28px;
    			}
    			&:hover, &:focus, &.active {
    				background-color: @redColor;
    				color: @whiteColor;
    				text-decoration: none;
    				&:after, &::after {
    					background-position: -47px 0px; 
    				}	
    			}
    		}
    	}
    	//calendar
    	.bootstrap-datetimepicker-widget {
			.datepicker-years .picker-switch {
				cursor: default !important;
				background: inherit !important;
			}
    		thead {
    			tr {
    				height: 50px;
    				line-height: 50px;
    				border-bottom: 1px solid #e8e8e8;
    			}
	    		th {
	    			height: auto;
	    			line-height: normal;
	    			padding: 0;
	    			&.prev, &.next {
	    				font-size: 0;
						span {
							font-size: 0;
							&:before, &::before {
			    				content: "";
			    				display: inline-block;
			    				width: 19px;
			    				height: 19px;
			    				background: url(../images/sprites/sprites.png) no-repeat -19px -28px;
			    				vertical-align: middle;
							}
						}
	    			}
	    			&.next {
	    				span {&:before,&::before{background-position: 0 -28px;}}    				
	    			}
	    			&.picker-switch {
	    				text-transform: uppercase;
	    				font-size: 15px;
	    				font-weight: 500;
	    			}
	    			&.dow {
	    				padding: 12.5px 0 12.5px 0;
			        	&:before, &::before, &:nth-of-type(7n + 0):after, &:nth-of-type(7n + 0)::after {
			        		content: "";
							border: 1px solid #e8e8e8;
							border-width: 0 0 0 1px;
							width: 1px;
							height: 35px;
							display: inline-block;
							vertical-align: middle;
							float: left;
			        	}  
			        	&:nth-of-type(7n + 0) {
			        		width: ~"calc(100% - 2px)";
				        	&:after, &:after {
								border-width: 0 1px 0 0;
								float: right;
				        	}
			        	}	    				
	    				span {	
	    					width: 35px;
	    					height: 35px;
							line-height: 35px;	
	    					text-transform: uppercase;
	    					color: @whiteColor;
	    					font-size: 15px;
	    					font-weight: 500;
	    					background-color: #282828;
	    					display: inline-block;
							-webkit-border-radius: 17.5px;
							-moz-border-radius: 17.5px;
							border-radius: 17.5px;  
							margin: 0 auto;  			
	    				}
	    			}
	    		}
    		}
    		tbody {
    			tr {
    				height: 60px;
    				line-height: 60px;
    				border-bottom: 1px solid #e8e8e8;
    			}
    			td {
    				padding: 0;
    				color: @blackColor;
	    			&.day {
	    				background-color: transparent;
			        	&:before, &::before, &:nth-of-type(7n + 0):after, &:nth-of-type(7n + 0)::after {
			        		content: "";
							border: 1px solid #e8e8e8;
							border-width: 0 0 0 1px;
							width: 1px;
							height: 35px;
							display: inline-block;
							vertical-align: middle;
							float: left;
			        		position: static;
			        		bottom: 0;
			        		right: 0;
			        	}  
			        	&:nth-of-type(7n + 0) {
			        		width: ~"calc(100% - 2px)";
				        	&:after, &:after {
								border-width: 0 1px 0 0;
								float: right;
				        	}
			        	}
	    				span {	
	    					width: 35px;
	    					height: 35px;
							line-height: 35px;	
	    					text-transform: uppercase;
	    					font-size: 15px;
	    					font-weight: 500;
	    					display: inline-block;
							margin: 0 auto;
							color: @whiteColor;
	    					background-color: @redColor;
	    					-webkit-border-radius: 17.5px;
							-moz-border-radius: 17.5px;
							border-radius: 17.5px;  
							z-index: -1; 			
	    				}

			        	&.today:before, &.today::before {
							border: 1px solid #e8e8e8;
			        		border-width: 0 0 0 1px;
			        		position: static;
			        		bottom: 0;
			        		right: 0;
			        	}
		    		}	
		    		span.old, span.new {
		    			background-color: #b9b9b9;
		    		}	
		    		span {
		    			background-color: @redColor;
		    		}	
		    		&.disabled span {
		    			color: #b9b9b9;
		    			background-color: transparent;
		    		}
    			}
    		}
    	}
    }
    &--grey {
		position: relative;
        padding: 30px 0 30px 0;
        margin: -30px 0 30px 0;
        .page-header {
        	border-color: #cfcfcf;
        	margin-bottom: 0;
        }
        .table-responsive {
        	border-color: #cfcfcf;
        	border-top: 0;
        }
        .table > tbody > tr {
        	> td {
	        	border: 1px solid #cfcfcf;
	        	border-width: 0 0 1px 0;
	        	padding: 14px 12px 6px 0;
	        	&:before, &::before {
	        		content: "";
					border: 1px solid #cfcfcf;
					border-width: 0 0 0 1px;
					width: 1px;
					height: 35px;
					display: inline-block;
					vertical-align: middle;
					margin: -8px 10px 0 0;
					float: left;
	        	}  
				&:last-of-type {
					padding-right: 0;
					&:before, &::before {margin-right: 16px;}
					&:after, &::after {
		        		content: "";
						border: 1px solid #cfcfcf;
						border-width: 0 1px 0 0;
						width: 1px;
						height: 30px;
						display: inline-block;
						vertical-align: middle;
						margin: -6px 0 0 10px;
						float: right;
					}
				}    
				&:nth-child(3n+1) {
					width: 70px;
					color: @redColor;
					text-align: center;
				}
				&:nth-child(3n+2) {
					width: 70px;
					text-align: center;
				}	
				&.win {color: #4da60c;}
				&.lose {color: @redColor;}
        	}
        }
    }
    &--o-klubu {
		position: relative;
        padding: 30px 0 0 0;
        margin: -30px 0 0 0;
    	background-color: @whiteColor;

    	.page-header {
    		text-align: center;
    		h1 {
    			color: @redColor;
    			font-size: 30px;
    			margin-bottom: 20px;
    		}
    		margin-bottom: 35px;
    	} 	
    }
    &--hraci {
		position: relative;
        padding: 30px 0 0 0;
        margin: -30px 0 0 0;
    	background-color: @whiteColor;

    	.page-header {
    		text-align: center;
    		h1 {
    			color: @redColor;
    			font-size: 30px;
    			margin-bottom: 20px;
    		}
    		margin-bottom: 35px;
    	}

    	.hrac {
    		margin-bottom: 50px;
    		&__image {
    			display: inline-block;
    			margin-right: 30px;
    			img {
	                -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.4);
	                -moz-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.4);
	                box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.4);  

	            }  			
    		}
    		&__text {
    			display: inline-block;
    			vertical-align: middle;
    			line-height: 1.7;
    			font-size: 15px;
    			width: ~"calc(100% - 180px)";
    			&--item {
    				display: block;
    				span {
    					font-weight: 800;
    				}
    			}
    		}
    	}
    }
    &--tabulka {
		position: relative;
        padding: 30px 0 40px 0;
        margin: -30px 0 0 0;
    	background-color: @whiteColor;

    	.page-header {
    		text-align: center;
    		h1 {
    			color: @redColor;
    			font-size: 30px;
    			margin-bottom: 20px;
    		}
    		margin-bottom: 0;
    	}
        .table-responsive {
        	border-color: #cfcfcf;
        	border-top: 0;
        }
        .table {
	        font-size: 15px;
	        margin: 0 auto;
        	width: 680px;

        	th, td {
	        	border: 1px solid #cfcfcf;
	        	border-width: 0 0 1px 0;
	        	padding: 20px 0 12px 0;
	        	text-align: center;
	        	&:before, &::before {
	        		content: "";
					border: 1px solid #cfcfcf;
					border-width: 0 0 0 1px;
					width: 1px;
					height: 35px;
					display: inline-block;
					vertical-align: middle;
					margin: -8px 0 0 0;
					float: left;
	        	}  
				&:last-of-type {
					padding-right: 0;
					&:before, &::before {margin-right: 16px;}
					&:after, &::after {
		        		content: "";
						border: 1px solid #cfcfcf;
						border-width: 0 1px 0 0;
						width: 1px;
						height: 30px;
						display: inline-block;
						vertical-align: middle;
						margin: -6px 0 0 10px;
						float: right;
					}
				}   
			    // Large, Medium and Small devices
			    @media (max-width: @screen-xs-max) {
			    	&:first-of-type {
		        		&:before, &::before {
		        			border-width: 0;
		        		}
			    	}
			    	&:last-of-type {
		        		&:after, &::after {
		        			border-width: 0;
		        		}
			    	}
			    } 
        	}
        	th {
				&:nth-child(8n+1) {
					width: 70px;
				}
				&:nth-child(8n+2) {
					width: 190px;
				}	
				&:nth-child(8n+3) {
					width: 85px;
				}
				&:nth-child(8n+4) {
					width: 55px;
				}	
				&:nth-child(8n+5) {
					width: 55px;
				}	
				&:nth-child(8n+6) {
					width: 55px;
				}	
				&:nth-child(8n+7) {
					width: 85px;
				}	
				&:nth-child(8n+8) {
					width: 85px;
				}	
        	}
        	td {
				&:nth-child(8n+2) {
					text-align: left;
					&:before, &::before {
						margin-right: 20px;
					}
				}	
        	}
	        tr {
	        	&.kampa {
	        		color: @redColor;
	        		font-weight: 800;
	        	}
			    // Large, Medium and Small devices
			    @media (max-width: @screen-xs-max) {
			    	&:last-of-type {
		        		td {
		        			border-bottom: 0;
		        		}
			    	}
			    } 
	        }
        }
    }
    &--fotky {
		position: relative;
        padding: 30px 0 0 0;
        margin: -30px 0 0 0;
    	background-color: @whiteColor;

    	.page-header {
    		text-align: center;
    		h1 {
    			color: @redColor;
    			font-size: 30px;
    			margin-bottom: 20px;
    		}
    		margin-bottom: 35px;
    	}
		//
		// Gallery
		//---------------------------------------------------
		.gallery-padding {
		    padding-right: 10px;
		    padding-left: 10px;
		}
	    .gallery-pic {
	        text-align: center;
	        position: relative;
	        display: inline-block;
	        -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.4);
	        -moz-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.4);
	        box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.4);
	        overflow: hidden;

	        &:hover, &--active {
	            .gallery-pic__image:after, .gallery-pic__image::after {
	                background-color: rgba(0, 0, 0, 0.6);
	            }
	            .gallery-pic__overlay {               
	                top: 55%;
	                opacity: 1;
	            }
	        }

	        &__image {
	            &:after, &::after {
	                content: "";
	                width: 100%;
	                height: 100%;
	                background-color: rgba(0, 0, 0, 0);
	                position: absolute;
	                top: 0;
	                left: 0;
	                right: 0;
	                -webkit-transition: all 0.3s;
	                -o-transition: all 0.3s;
	                transition: all 0.3s;
	            }
	        }
	        &__overlay {
	            display: inline-block;
	            position: absolute;
	            top: 150%;
	            left: 0;
	            right: 0;
	            transform: translate(0, -70%);
	            -webkit-transform: translate(0, -70%);
	            -moz-transform: translate(0, -70%);
	            -o-transform: translate(0, -70%);
	            -ms-transform: translate(0, -70%);
	            -webkit-transition: all 0.3s;
	            -o-transition: all 0.3s;
	            transition: all 0.3s;
	            opacity: 0;
	            span {
	                font-size: 32px;
	                color: @gallery-picOverlayColor;
	                padding: 6px;

	                // Medium devices
	                @media (min-width: @screen-xs-min) and (max-width: @screen-xs-max) {
	                    font-size: 64px;
	                    padding: 12px;
	                }
	            }
	        }  
	        &__title {
	            padding: 10px;
	            margin-bottom: 40px;
	            font-size: 15px;
	            color: #444444;
	            line-height: normal;

	            // ExtraSmall devices
	            @media (min-width: @screen-xs-min) and (max-width: @screen-xs-max) {
	                font-size: 24px;
	                padding: 24px 16px;
	            }

	            // Large, Medium and Small devices
	            @media (min-width: @screen-sm-min) {
	                font-size: 20px;
	                padding-top: 20px;
	                margin-bottom: 40px;
	            }
	        }  
	    }
	}
    &--kampa-cup {
		position: relative;
        padding: 30px 0 40px 0;
        margin: -30px 0 0 0;
    	background-color: @whiteColor;
		-webkit-box-shadow: 0px 3px 7px 0px rgba(0,0,0,0.35);
		-moz-box-shadow: 0px 3px 7px 0px rgba(0,0,0,0.35);
		box-shadow: 0px 3px 7px 0px rgba(0,0,0,0.35);

    	.page-header {
    		text-align: center;
    		h1 {
    			color: @redColor;
    			font-size: 30px;
    			margin-bottom: 20px;
    			text-transform: uppercase;
    		}
    		margin-bottom: 35px;
    	}

    	h2 {
    		font-size: 25px;
    		margin-top: 20px;
			margin-bottom: 10px;
    	}

		.col-md-3:first-of-type table {
	        	border-top: 1px solid #cfcfcf;
	        }
		.col-md-3:last-of-type .table-responsive {
	        	margin-bottom: 35px;
	        }
        .table-responsive {
        	border-color: #cfcfcf;
        	border-top: 0;
        	max-width: 480px;
        	margin: 0 auto;

        }
        .table {
	        font-size: 15px;
        	max-width: 480px;
        	min-width: 200px;
        	margin: 0 auto;
	          // Medium devices
	          @media (min-width: @screen-md-min) {
	        	border-top: 1px solid #cfcfcf;
	          }

        	th, td {
	        	border: 1px solid #cfcfcf;
	        	border-width: 0 0 1px 0;
	        	padding: 20px 0 12px 0;
	        	text-align: center;
	        	&:before, &::before {
	        		content: "";
					border: 1px solid #cfcfcf;
					border-width: 0 0 0 1px;
					width: 1px;
					height: 35px;
					display: inline-block;
					vertical-align: middle;
					margin: -8px 0 0 0;
					float: left;
	        	}  
				&:last-of-type {
					padding-right: 0;
					&:before, &::before {margin-right: 16px;}
					&:after, &::after {
		        		content: "";
						border: 1px solid #cfcfcf;
						border-width: 0 1px 0 0;
						width: 1px;
						height: 30px;
						display: inline-block;
						vertical-align: middle;
						margin: -6px 0 0 10px;
						float: right;
					}
				}   
			    // Large, Medium and Small devices
			    @media (max-width: @screen-xs-max) {
			    	&:first-of-type {
		        		&:before, &::before {
		        			border-width: 0;
		        		}
			    	}
			    	&:last-of-type {
		        		&:after, &::after {
		        			border-width: 0;
		        		}
			    	}
			    } 
        	}
        	td {
				&:nth-child(2n+1) {
					width: 70px;
				}
        	}
        	td {
				&:nth-child(2n+2) {
					text-align: left;
					width: ~"calc(100% - 70px)";
					&:before, &::before {
						margin-right: 20px;
					}
				}	
        	}
	        tr {
			    // Large, Medium and Small devices
			    @media (max-width: @screen-xs-max) {
			    	&:last-of-type {
		        		td {
		        			border-bottom: 0;
		        		}
			    	}
			    } 
	        }
        }
        ul {
        	padding-left: 20px;
        	> li {
	        	font-size: 15px;
	        	color: @redColor;
	        	line-height: 1.7;
	        	span {
	        		color: @blackColor;
	        	}
        	}
        }
    	.partners {
    		margin-bottom: 30px;
    		> li {
    			margin-top: 15px;
			    // Large, Medium and Small devices
			    @media (min-width: @screen-sm-min) {
	    			width: ~"calc(50% - 20px)";
	    			display: inline-block;
	    			vertical-align: middle;
	    			&:before, &::before {
	    				content: "• ";
	    				font-size: 22px;
	    				position: absolute;
	    				margin: -5px 0 0 -19px;
	    			}
			    }
    		}
    		&__title {
    			display: block;
    			color: @redColor;
    			font-weight: 800;
    		}
    		&__text {
    			display: block;
    		}
    	}
        p {
        	line-height: 1.8;
        }
	    .col-sm-5 {
	    	text-align: center;
		    // Large, Medium and Small devices
		    @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
	    		text-align: right;
		    }
	    }
    }
    &--kampa-cup-grey {
		position: relative;
        padding: 30px 0 30px 0;
        margin: -30px 0 30px 0;
        .page-header {
        	border-color: #cfcfcf;
        	margin-top: 50px;
        	margin-bottom: 40px;
        	text-align: center;
        	text-transform: uppercase;
        }
        h3 {
        	font-size: 25px;
        	margin-top: 40px;
        	margin-bottom: 20px;
        	&.article-item__title {
        		margin-top: 0;
        		margin-bottom: 30px;
        	}
        }
        ul {
        	padding-left: 20px;
    		margin-bottom: 30px;
        	> li {
	        	font-size: 15px;
	        	color: @redColor;
	        	line-height: 1.7;
	        	span {
	        		color: @blackColor;
	        	}
        	}
        }
        .form-horizontal {
        	margin-top: 30px;
        	line-height: 30px;
	        .control-label {
	        	text-align: left;
	        	font-weight: 500;
	        	padding: 0;
	        }
	        .form-group {
	        	margin-right: 0;
	        	margin-left: 0;
	        }
            .form-control {
                font-size: 13px;
                height: 40px;
                line-height: 40px;
                padding: 0 15px;
                -webkit-border-radius: 15px;
                -moz-border-radius: 15px;
                border-radius: 15px;                
            }

            textarea.form-control {
            	height: auto;
            	line-height: 20px;
                padding: 10px 15px;
            }
	    }
    }
    &--kontakt {
		position: relative;
        padding: 30px 0 40px 0;
        margin: -30px 0 0 0;
    	background-color: @whiteColor;
    	font-size: 15px;
    	h1 {
    		text-transform: uppercase;
    		text-align: center;
    	}
    	.page-header {
    		margin-bottom: 10px;
    	}
		h2 {
			margin-bottom: 20px;
		}
		span {
			&.red {
				color: @redColor;
				font-weight: 800;
			}
		}
		p {
			margin-bottom: 20px;
		}
	    .col-sm-6:nth-child(2) {
	    	text-align: center;
		    // Large, Medium and Small devices
		    @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
	    		text-align: right;
		    }
	    }
	    .contact-item {
	    	@media (max-width: @screen-xs-max) {
		    	h2, h3 {text-align: center;}
	    	}
	    	&__title {
	    		color: @redColor;
	    		font-size: 25px;
	    		font-weight: 500;
			    text-align: left;
	    	}
	        &__text {
	        	font-size: 15px;
	        	font-weight: 500;
	        	color: @blackColor;
	        	.col-sm-6 {padding: 0;}
		    	@media (max-width: @screen-xs-max) {
			    	max-width: 244px;
			    	margin: 0 auto;
		    	}
	            ul {
	            	margin-top: 15px;
	            	font-size: 0;
	                font-weight: 500;
	                padding: 0;
	                vertical-align: top;
	                > li {
	                    font-size: 14px;
	                    display: block;
	                    padding: 0 30px 0px 40px;
	                    margin-bottom: 15px;
	                    position:relative;
	                    min-height:25px;
	                    &:before, &::before {
	                        font: normal normal normal 14px/1 FontAwesome;
	                        font-size: inherit;
	                        text-rendering: auto;
	                        -webkit-font-smoothing: antialiased;
	                        -moz-osx-font-smoothing: grayscale;
	                        top: 50%;
	                        transform: translate(0, -50%);
	                        -webkit-transform: translate(0, -50%);
	                        -moz-transform: translate(0, -50%);
	                        -o-transform: translate(0, -50%);
	                        -ms-transform: translate(0, -50%);
	                        font-size: 22px;
	                        content: "";
	                        position:absolute;
	                        left:0;        
	                        color: @redColor;               
	                    }
	                    &.map-marker {&:before, &::before{content: "\f041";}}
	                    &.earphone {&:before, &::before{content: "\f095";margin-top: -1px;}}
	                    &.envelope {&:before, &::before{content: "\f003";margin-top: -3px;}}
	                }
	            }
	        }

		    .form {
				width: 100%;
				margin: 0 auto;
			    text-align: center;

			    // extraSmall devices
			    @media (min-width: @screen-xs-min) and (max-width: @screen-xs-max) {
			    	width: 438px;
			    }
			    // Large, Medium and Small devices
			    @media (min-width: @screen-sm-min) {
			    	text-align: right;
			    }
		        &-group {
		            margin-bottom: 8px;
		        }

		        &-control {     
	                font-size: 15px;
	                height: 40px;
	                line-height: 40px;
	                padding: 0 15px;
	                -webkit-border-radius: 15px;
	                -moz-border-radius: 15px;
	                border-radius: 15px; 

		            &::-webkit-input-placeholder {
		               color: #444444;
		            }

		            &:-moz-placeholder { /* Firefox 18- */
		               color: #444444; 
		            }

		            &::-moz-placeholder {  /* Firefox 19+ */
		               color: #444444; 
		            }

		            &:-ms-input-placeholder {  
		               color: #444444;  
		            }
		        }
		    }

		    textarea.form-control {
		    	margin-top: 20px;
		    	margin-bottom: 20px;
		    	height: 125px;
		    }  
	    }
    }
    &--download {
		position: relative;
        padding: 30px 0 40px 0;
        margin: -30px 0 0 0;
    	background-color: @whiteColor;

    	.download {
			&__item {
				display: inline-block;
				padding: 10px 0;
				margin: 10px 0;
				height: 60px;
				color: @blackColor;
				&:before, &::before {
			        content: "";
			        display: inline-block;
			        background: url(../images/icony.png) no-repeat 0 0;
			        width: 49px;
			        height: 60px;
			        vertical-align: middle;
			        margin: -5px 15px 0 10px;
				}
				&.doc {
					&:before, &::before {
						background-position: 0 0;
					}
				}
				&.pdf {
					&:before, &::before {
						background-position: -55px 0;
					}
				}
				&.ai {
					&:before, &::before {
						background-position: -111px 0;
					}
				}
				&.xls {
					&:before, &::before {
						background-position: -168px 0;
					}
				}
				&.jpg {
					&:before, &::before {
						background-position: -226px 0;
					}
				}
			}
    	}
    }
}