//
// Body, html
//---------------------------------------------------
body, html {
    font-family: "open-sans";
    color: #434343;
    background-color: @greyColor;
    height: 100%;
}

//
// Typography
//---------------------------------------------------
h1, h2, h3, h4 {
  
}

h1 {

}

h2 {
  color: @redColor;
}

h3 {
  color: @redColor;    
}

p {
    
}

ul {
    > li {
    }
        
}

a {
    color: @redColor;
    &:hover, &:focus {
      color: @redColor;
      text-decoration: underline;        
    }
}

//
// Essential classes
//---------------------------------------------------

.affix {
    top: 0;
    z-index: 999;
    width: 100%;
}

.no-padding {
  padding: 0 !important;
}

.navbar-collapse {
  padding: 0 !important;
}

.navbar-toggle {
  margin: 12.5px 0;
  padding: 9px 3px;
  .icon-bar {
    width: 24px;
  }
}

.page-header {
  margin-top: 10px;
  border-color: #d6d6d6;
  h1, h2 {
    font-size: 30px;
    font-weight: 500;
    color: @redColor;
  }
}

.ttu {
  text-transform: uppercase;
}

.page-wrap {
  min-height: 100%;
  /* equal to footer height */
  margin-bottom: -205px; 
    // Small devices
    @media (min-width: @screen-sm-min) {
      margin-bottom: -117px;
    } 
}
.page-wrap:after {
  content: "";
  display: block;
}
.footer, .page-wrap:after {
  height: 205px;
    // Small devices
    @media (min-width: @screen-sm-min) {
      height: 117px;
    } 
}

//
// Screen resolutions
//---------------------------------------------------
    // Extra small devices
    @media (max-width: @screen-xs-max) { 
    }

    // Small devices
    @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
    }

    // Medium devices
    @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
    }

    // Large devices
    @media (min-width: @screen-lg-min) {
    }

    // Small devices and Extra small devices
    @media (max-width: @screen-sm-max) {
    }

    // Large devices and Medium device
    @media (min-width: @screen-md-min) {
    }

    // Medium devices and Small devices and Extra small devices
    @media (max-width: @screen-md-max) {
    }

//
// Owl carousel fix
//---------------------------------------------------
.owl-carousel {
    z-index: 1;
}

.owl-wrapper-outer {
    z-index: 1;
}

.owl-stage {
    z-index: 1;
}

.owl-stage-outer {
    z-index: 2;
}

.owl-item {
    z-index: 1;
}

//
// Slider
//---------------------------------------------------
.wrapper-slider {
  position: relative;
}
.slide {
  background-image: url(../img/1920x600.png);
  background-position: center;
  height: 340px;
  text-align: center;
  h1 {
    font-size: 65px;
    color: #fff;
    border: 0;
  }
  p {
    font-size: 20px;
    color: #fff;
    margin-bottom: 30px;
    max-width: 680px;
    margin-left: auto;
    margin-right: auto;
  }
}
.owl-nav {
  position: absolute;
  z-index: 2;
  top: 237px;
  left: 36px;
  right: 36px;
  height: 0;
  overflow: visible;
  .owl-prev, .owl-next {
    width: 28px;
    height: 116px;
    font-size: 0;
    background-image: url(../images/owl-nav.png);
  }
  .owl-prev {
    background-position: 0 0;
    float: left;
  }
  .owl-next {
    background-position: -28px 0;
    float: right;
  }
}

// Extra small devices
@media (max-width: @screen-xs-max) {
  .slide {
    padding-top: 0;
  }
}
// Small devices and Extra small devices
@media (max-width: @screen-sm-max) {
  .owl-nav {
    display: none;
  }
}

.btn-red {
    padding: 10px 19px 7px 19px;
    font-size: 15px;
    line-height: normal;
    font-weight: 500;
    text-decoration: none;
    color: @whiteColor;
    border: 0;
    background-color: @redColor;
    -webkit-border-radius: 19px;
    -moz-border-radius: 19px;
    border-radius: 19px;  
  
    &:after, &::after {
        content: "";
        display: inline-block;
        background: url(../images/sprites/sprites.png) no-repeat 0 0;
        width: 28px;
        height: 28px;
        vertical-align: middle;
        margin: -3px 0 0 10px;
    }              
    &:hover, &:focus, &.active {
      background-color: #222222;
      color: @whiteColor;
      text-decoration: none;
    }
}

//
// Articles
//--

  .article-item {
      margin-bottom: 30px;
      &__text {
          text-align: left;
          max-width: 567px;
          margin: 0 auto;
          // Medium devices
          @media (min-width: @screen-md-min) {
              max-width: 100%;
          }
          p {
            margin-bottom: 25px;
            line-height: 1.6;
            font-size: 15px;
          }
      }
      &__image {
          font-size: 0;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
          @media (min-width: @screen-sm-min) {
              picture:nth-child(3n-1) img {
                  margin: 0 9px 9px 9px;
              }
          }

          img { 
              margin: 15px auto;
              -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.4);
              -moz-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.4);
              box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.4);
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      border-radius: 10px;
              @media (min-width: @screen-sm-min) {
                  display: inline-block;
                  margin: 0 auto 9px auto;
              }                
          }

      }
  }   

  .forum-block {
    background-color: #f7f7f7;
    padding: 15px;
    margin-bottom: 10px;
    color: #444444;
    .red {
      color: @redColor;
      font-weight: 800;
    } 
    &__title {
      color: @blackColor;
      font-size: 15px;
      margin-bottom: 15px;
      // Extra small devices
      @media (max-width: @screen-xs-max) {
        span {
          display: block;
          margin-bottom: 5px;
        }
      }
      @media (min-width: @screen-sm-min) {
        span {
          &:after, &::after {
            content: "|";
            color: @blackColor;
            font-size: 18px;
            padding: 0 10px;
          }
        }
      }
    }
    &__text {
      font-size: 15px;
    }
    &__footer {
      border: 1px solid #e8e8e8;
      border-width: 1px 0;
      margin: 0 -15px -15px -15px;
      padding: 15px;
      font-size: 13px;
    }
    &__date {
      &:after, &::after {
        content: "|";
        color: @blackColor;
        font-size: 18px;
        padding: 0 10px;
      }
    }
    &__answer {
      font-weight: 800;
      &:after, &::after {
        content: "";
        display: inline-block;
        width: 19px;
        height: 19px;
        margin-left: 5px;
        vertical-align: text-bottom;
        background: url(../images/sprites/sprites.png) no-repeat 0 -28px;
      }
    }
    &.answer {
      background-color: #f5ebeb;
      .forum-block__footer {
        border-color: #e9cfcf;
      }
    }
  }